<script setup lang="ts">
import { cn } from '@/lib/utils';

import type { HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<{
    class?: HTMLAttributes['class'];
    userDetailsClass?: HTMLAttributes['class'];
    email?: string;
    fullName?: string;
    truncate?: boolean;
    isLarge?: boolean;
    isSmall?: boolean;
    isOption?: boolean;
  }>(),
  {
    class: null,
    userDetailsClass: null,
    email: '',
    fullName: '',
    truncate: false,
    isLarge: false,
    isSmall: false,
    isOption: false
  }
);
</script>

<template>
  <div :class="cn('flex gap-2.5', props.isLarge && 'gap-4', props.class)">
    <div
      :class="
        cn(
          'shrink-0 bg-gray-100 rounded-lg justify-center items-center text-gray-800 text-sm w-10 h-10 inline-flex pointer-events-none',
          props.isSmall && 'text-xs font-semibold w-7 h-7',
          props.isLarge && 'rounded-xl text-2xl w-16 h-16'
        )
      "
    >
      <span>{{ fullName.toUpperCase()[0] }}</span>
    </div>
    <div :class="userDetailsClass">
      <p
        :class="
          cn(
            'break-words text-sm',
            props.isSmall && 'text-xs font-semibold',
            props.isLarge && 'text-lg font-semibold',
            truncate && 'truncate',
            !isOption && 'max-w-64'
          )
        "
        :title="fullName"
      >
        {{ fullName }}
      </p>
      <p
        :class="
          cn(
            'text-xs font-normal text-gray-500 break-words',
            truncate && 'truncate',
            props.isLarge && 'text-sm font-medium',
            !isOption && 'max-w-64'
          )
        "
        :title="email"
      >
        {{ email }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>
