<script setup lang="ts">
import { toRefs } from 'vue';

import { ProductLink } from '@/layouts/Sidebar/components';

import PPIcon from 'assets/icons/patents-platform.svg?component';
import IDIcon from 'assets/icons/idea-database.svg?component';

import { cn } from '@/lib/utils';

const props = defineProps<{
  isSidebarOpen: boolean;
  isPatentsPlatformEnabled: boolean;
  isIdeaDatabaseEnabled: boolean;
}>();

const { isSidebarOpen, isPatentsPlatformEnabled, isIdeaDatabaseEnabled } =
  toRefs(props);

const { VITE_G2_URL, VITE_INVENTION_URL } = import.meta.env;
</script>

<template>
  <div class="w-full">
    <div
      v-if="isIdeaDatabaseEnabled || isPatentsPlatformEnabled"
      :class="cn('flex-col justify-start items-start inline-flex gap-4 w-full')"
    >
      <span
        :class="
          cn(
            'text-gray-500 text-xs font-semibold leading-none dark:text-white uppercase',
            !isSidebarOpen && 'self-center'
          )
        "
      >
        {{ $t('organization.products') }}
      </span>
      <ProductLink
        v-if="isPatentsPlatformEnabled"
        :is-sidebar-open="isSidebarOpen"
        :href="VITE_G2_URL"
        translation="organizationDashboard.patents_platform"
      >
        <PPIcon />
      </ProductLink>
      <ProductLink
        v-if="isIdeaDatabaseEnabled"
        :is-sidebar-open="isSidebarOpen"
        :href="VITE_INVENTION_URL"
        translation="organizationDashboard.idea_database"
      >
        <IDIcon />
      </ProductLink>
    </div>
  </div>
</template>
