import type { ApiTableResponse } from '@/types/api';
import type { User } from '@/types/users';

export const API_DISABLED = 'API_DISABLED';

export const ORGANIZATION_USER_PRESETS_API = 'presets';
export const USER_API = 'user';
export const USERS_API = 'users';
export const USERS_SIMPLIFIED_API = 'usersSimplified';
export const USERS_SIMPLIFIED_LOGIN_TYPE_API = 'usersSimplifiedLoginType';
export const USERS_SIMPLIFIED_NOTE_API = 'usersSimplifiedNote';
export const USERS_SIMPLIFIED_STATUS_API = 'usersSimplifiedStatus';
export const USERS_SIMPLIFIED_GROUP_API = 'usersSimplifiedGroup';
export const USERS_SIMPLIFIED_ROLES_API = 'usersSimplifiedRoles';
export const USERS_SIMPLIFIED_TYPE_API = 'usersSimplifiedType';

export const GROUPS_API = 'groups';
export const GROUPS_SIMPLIFIED_API = 'groupsSimplified';
export const GROUP_API = 'group';

export const TOKENS_API = 'tokens';
export const TOKEN_API = 'token';
export const TOKENS_SIMPLIFIED_API = 'tokensSimplified';

export const ORGANIZATION_API = 'organization';

export const SESSIONS_API = 'sessions';

export const DEFAULT_USERS_SORT = 'full_name';
export const DEFAULT_GROUPS_SORT = 'name';
export const DEFAULT_TOKENS_SORT = 'name';

export const INITIAL_TABLE_RESPONSE: ApiTableResponse<never> = {
  data: [],
  per_page: 10,
  current_page: 1,
  total: 0,
  last_page: 1
} as const;

export const INITIAL_USER: User = {
  id: 1,
  patentsProductEnabled: 0,
  patentsUserType: null,
  patentsSelectedRoles: [],
  ideaDatabaseProductEnabled: 0,
  ideaDatabaseRoles: [],
  firstName: '',
  lastName: '',
  email: '',
  isSsoEnabled: 0,
  groups: [],
  containers: [],
  note: null,
  history: [],
  createdAt: '',
  updatedAt: ''
};

export const INITIAL_GROUP = {
  id: 1,
  name: '',
  description: '',
  users: []
} as const;

export const INITIAL_TOKEN = {
  id: 1,
  name: '',
  allowedIpAddresses: '',
  active: true,
  token: ''
} as const;

export type AllowedApiKeys =
  | typeof API_DISABLED
  | typeof GROUP_API
  | typeof GROUPS_API
  | typeof GROUPS_SIMPLIFIED_API
  | typeof ORGANIZATION_API
  | typeof ORGANIZATION_USER_PRESETS_API
  | typeof SESSIONS_API
  | typeof TOKEN_API
  | typeof TOKENS_API
  | typeof TOKENS_SIMPLIFIED_API
  | typeof USER_API
  | typeof USERS_API
  | typeof USERS_SIMPLIFIED_API
  | typeof USERS_SIMPLIFIED_GROUP_API
  | typeof USERS_SIMPLIFIED_LOGIN_TYPE_API
  | typeof USERS_SIMPLIFIED_NOTE_API
  | typeof USERS_SIMPLIFIED_ROLES_API
  | typeof USERS_SIMPLIFIED_STATUS_API
  | typeof USERS_SIMPLIFIED_TYPE_API;

export type SimplifiedDataKeys =
  | typeof USERS_SIMPLIFIED_API
  | typeof GROUPS_SIMPLIFIED_API
  | typeof TOKENS_SIMPLIFIED_API;

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
} as const;

export type HttpCodes = (typeof HTTP_CODES)[keyof typeof HTTP_CODES];
