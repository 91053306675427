<script setup lang="ts">
import { inject, type Ref, toRefs } from 'vue';

import { Ellipsis } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { logout } from '@/api/user/logout';

import {
  CustomDropdownMenu,
  CustomDropdownMenuItem
} from '@/components/custom/CustomDropdownMenu';

import { Button } from '@/components/ui/Button';
import { DropdownMenuSeparator } from '@/components/ui/DropdownMenu';

import { BUTTON_VARIANTS } from '@/constants/component';
import { SETTINGS_ROUTE } from '@/constants/routes';

import type { CurrentUser } from '@/types/users';

const props = defineProps<{
  isSidebarOpen: boolean;
}>();

const { isSidebarOpen } = toRefs(props);

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

function redirectToSettings() {
  window.location.pathname = SETTINGS_ROUTE;
}

const userDropdownOptions = [
  { label: 'common.settings', onClick: redirectToSettings },
  { label: 'common.logout', onClick: () => logout() }
] as const;
</script>

<template>
  <div
    :class="
      cn(
        'h-18 px-5 py-4 border-t border-gray-200 grid grid-cols-6 grid-rows-1',
        isSidebarOpen ? 'justify-between' : 'justify-center'
      )
    "
  >
    <div
      :class="
        cn(
          'flex gap-2.5',
          isSidebarOpen
            ? 'col-span-5'
            : 'col-span-6 justify-center cursor-pointer'
        )
      "
    >
      <div>
        <div>
          <CustomDropdownMenu
            v-if="!isSidebarOpen"
            content-alignment="end"
            content-position="right"
            dropdown-class="grid col-span-1 justify-self-end [--strategy:absolute] [--placement:right]"
            trigger-class="bottom-4"
            :side-offset="24"
          >
            <template #trigger>
              <Button
                type="button"
                :variant="BUTTON_VARIANTS.icon"
                :class="
                  cn(
                    'w-10 h-10 bg-gray-100 rounded-lg justify-center items-center text-gray-800 text-sm inline-flex',
                    isSidebarOpen && 'mr-4'
                  )
                "
                >{{ currentUser.fullName.toUpperCase()[0] }}
              </Button>
            </template>
            <template #menu-header>
              <div
                class="flex w-52 justify-between p-2 cursor-default font-medium"
                @click.stop
              >
                <div
                  class="flex-col justify-center items-start inline-flex gap-0.5"
                >
                  <span
                    :title="currentUser.fullName"
                    class="text-black text-sm text-ellipsis break-words line-clamp-3"
                    >{{ currentUser.fullName }}</span
                  >
                  <span
                    :title="currentUser.email"
                    class="text-gray-500 text-xs text-ellipsis break-all line-clamp-3"
                  >
                    {{ currentUser.email }}</span
                  >
                </div>
              </div>
              <DropdownMenuSeparator />
            </template>
            <template #menu-options>
              <CustomDropdownMenuItem
                v-for="option in userDropdownOptions"
                :key="option.label"
                :item-label="$t(option.label)"
                @click="option.onClick"
              />
            </template>
          </CustomDropdownMenu>

          <span
            v-else
            :class="
              cn(
                'w-10 h-10 bg-gray-100 rounded-lg justify-center items-center text-gray-800 text-sm inline-flex pointer-events-none'
              )
            "
            >{{ currentUser.fullName.toUpperCase()[0] }}
          </span>
        </div>
      </div>

      <div v-if="isSidebarOpen" class="w-full max-w-[75%] my-auto">
        <div class="flex flex-col">
          <span
            :title="currentUser.fullName"
            class="text-black text-sm overflow-hidden whitespace-nowrap text-ellipsis max-w-[100%]"
            >{{ currentUser.fullName }}</span
          >
          <span
            :title="currentUser.email"
            class="text-gray-500 text-xs overflow-hidden whitespace-nowrap text-ellipsis max-w-[100%]"
          >
            {{ currentUser.email }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="isSidebarOpen" class="col-span-1 grid justify-self-end my-auto">
      <div class="h-5">
        <CustomDropdownMenu content-alignment="start">
          <template #trigger>
            <Button
              type="button"
              :variant="BUTTON_VARIANTS.icon"
              class="my-auto h-5 py-0 px-0"
            >
              <Ellipsis class="w-5 h-5 text-gray-800 shrink-0" />
            </Button>
          </template>
          <template #menu-options>
            <CustomDropdownMenuItem
              v-for="option in userDropdownOptions"
              :key="option.label"
              :item-label="$t(option.label)"
              @click="option.onClick()"
            />
          </template>
        </CustomDropdownMenu>
      </div>
    </div>
  </div>
</template>
