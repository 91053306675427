import axios from 'axios';
import request, { getQueryData } from '@/api/base';

import {
  createIdeaDatabaseRolesParams,
  parseIdeaDatabaseRoles
} from '@/lib/helpers';

import { DEFAULT_USERS_SORT } from '@/constants/api';
import { PRODUCT_PATENT_PLATFORM } from '@/constants/contentType';

import type {
  ApiErrorResponse,
  ApiResponse,
  ApiSimpleResponse,
  ApiTableParams,
  ApiTableResponse
} from '@/types/api';

import type {
  ListUser,
  User,
  UserSimplified,
  UserSimplifiedWithGroup,
  UserSimplifiedWithLoginType,
  UserSimplifiedWithNote,
  UserSimplifiedWithRole,
  UserSimplifiedWithStatus,
  UserSimplifiedWithType
} from '@/types/users';

import type {
  BulkCreateNotesParams,
  BulkChangeUserGroupsParams,
  BulkChangeUserLoginTypeParams,
  BulkChangeUserRolesParams,
  BulkChangeUserStatusParams,
  BulkChangeUserTypeParams,
  BulkDeleteAccountsParams,
  BulkDeleteNotesParams,
  CreateUserParams,
  ExportUsersParams,
  FetchBulkUsersParams,
  FetchBulkUsersSimplifiedParams,
  FetchBulkUsersSimplifiedByProductParams,
  SingleNoteParams,
  SingleUserResponse,
  UpdateUserParams
} from '@/api/user/types';

export async function fetchUsers(params?: ApiTableParams) {
  return await request
    .get<
      ApiTableResponse<ListUser>
    >(`${import.meta.env.VITE_API_VERSION}/users/${getQueryData({ params, defaultSortField: DEFAULT_USERS_SORT })}`)
    .then(res => {
      return {
        ...res.data,
        data: [
          ...(res.data.data.map(user => ({
            ...user,
            ideaDatabaseRoles: parseIdeaDatabaseRoles(user.ideaDatabaseRoles)
          })) as ListUser[])
        ]
      };
    });
}

export async function fetchUsersSimplified({
  user_ids,
  params,
  additionalFilters
}: FetchBulkUsersSimplifiedParams = {}) {
  return await request
    .get<ApiResponse<UserSimplified[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/users${getQueryData({ params, additionalFilters })}`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUser(userId: number) {
  return await request
    .get<
      ApiResponse<User>
    >(`${import.meta.env.VITE_API_VERSION}/users/${userId}`)
    .then(res => {
      return {
        ...res.data,
        data: {
          ...res.data.data,
          ideaDatabaseRoles: parseIdeaDatabaseRoles(
            res.data.data.ideaDatabaseRoles
          )
        }
      };
    });
}

export async function createUser(user: CreateUserParams) {
  return await request
    .post<SingleUserResponse>(`${import.meta.env.VITE_API_VERSION}/users`, user)
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function deleteUser(userId: number) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function updateUser(userId: number, userParams: UpdateUserParams) {
  return await request
    .patch<SingleUserResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}`,
      userParams
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function changeUserStatus(userId: number, value: boolean) {
  return await request
    .patch<{
      user: ListUser;
    }>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}/organizations/status`,
      {
        active: value
      }
    )
    .then(res => {
      return {
        ...res,
        data: {
          ...res.data,
          user: {
            ...res.data.user,
            ideaDatabaseRoles: parseIdeaDatabaseRoles(
              res.data.user.ideaDatabaseRoles
            )
          } as ListUser
        }
      };
    })
    .catch(error => error as ApiErrorResponse);
}

export async function addUserNote({ userId, note }: SingleNoteParams) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}/notes`,
      {
        note
      }
    )
    .catch(error => error as ApiErrorResponse);
}

export async function editUserNote({ userId, note }: SingleNoteParams) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}/notes`,
      {
        note
      }
    )
    .catch(error => error as ApiErrorResponse);
}

export async function deleteUserNote(userId: number) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}/notes`
    )
    .catch(error => error as ApiErrorResponse);
}

export async function fetchUsersSimplifiedWithNote({
  user_ids
}: FetchBulkUsersParams = {}) {
  return await request
    .get<ApiResponse<UserSimplifiedWithNote[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/notes`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUsersSimplifiedWithStatus({
  user_ids
}: FetchBulkUsersParams = {}) {
  return await request
    .get<ApiResponse<UserSimplifiedWithStatus[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/status`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUsersSimplifiedWithGroups({
  additionalFilters,
  user_ids
}: FetchBulkUsersSimplifiedParams = {}) {
  return await request
    .get<ApiResponse<UserSimplifiedWithGroup[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/groups${getQueryData({ additionalFilters })}`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUsersSimplifiedWithLoginType({
  user_ids
}: FetchBulkUsersSimplifiedParams) {
  return await request
    .get<ApiResponse<UserSimplifiedWithLoginType[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/login-types`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function bulkDeleteAccounts({
  user_ids
}: BulkDeleteAccountsParams) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/users`,
      { data: { user_ids } }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function fetchUsersSimplifiedWithType({
  user_ids
}: FetchBulkUsersParams = {}) {
  return await request
    .get<ApiResponse<UserSimplifiedWithType[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/roles${getQueryData({ additionalFilters: 'product_id=1' })}`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUsersSimplifiedWithRoles({
  user_ids
}: FetchBulkUsersParams = {}) {
  return await request
    .get<ApiResponse<UserSimplifiedWithRole[]>>(
      `${import.meta.env.VITE_API_VERSION}/users/fetch/roles${getQueryData({ additionalFilters: 'product_id=2' })}`,
      {
        ...(user_ids && { params: { user_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchUsersSimplifiedByProduct({
  user_ids,
  product
}: FetchBulkUsersSimplifiedByProductParams) {
  return await (product === PRODUCT_PATENT_PLATFORM
    ? fetchUsersSimplifiedWithType({ user_ids })
    : fetchUsersSimplifiedWithRoles({ user_ids }));
}

export async function bulkDeleteNotes({ user_ids }: BulkDeleteNotesParams) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/notes`,
      {
        data: { user_ids }
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkCreateNotes(params: BulkCreateNotesParams) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/notes`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeUserStatus(params: BulkChangeUserStatusParams) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/status`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeUserRoles(params: BulkChangeUserRolesParams) {
  return await request
    .put<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/idea-database-roles`,
      {
        ...params,
        roles: createIdeaDatabaseRolesParams(params.roles)
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeUserType(params: BulkChangeUserTypeParams) {
  return await request
    .put<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/patents-platform-roles`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeUserGroups(params: BulkChangeUserGroupsParams) {
  return await request
    .put<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/groups`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeUserLoginType(
  params: BulkChangeUserLoginTypeParams
) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/bulk-actions/login-type`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function exportUsersToExcel({ user_ids }: ExportUsersParams = {}) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/export`,
      {
        ...(user_ids && { user_ids })
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function triggerResetPassword(userId: number) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/${userId}/reset-password`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function uploadUsersCSV(file: File) {
  return await axios
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/users/import`,
      { file: file },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
