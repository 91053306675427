import axios from 'axios';

import {
  handleDownloadAction,
  parseContentDispositionHeaders
} from '@/lib/helpers';

import { FAILED_TO_DOWNLOAD_FILE } from '@/constants/errors';

export async function downloadExportFile(): Promise<void> {
  const path = window.location.pathname;
  const fileName = path.split('/')[path.split('/').length - 1];

  await axios
    .get<Blob>(`${import.meta.env.VITE_API_VERSION}/download/${fileName}`, {
      responseType: 'blob'
    })
    .then(response => {
      handleDownloadAction(
        response.data,
        parseContentDispositionHeaders(response)
      );
    })
    .catch(error => console.error(FAILED_TO_DOWNLOAD_FILE, error));
}
