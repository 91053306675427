<script setup lang="ts">
import { onBeforeMount, ref, watch, provide } from 'vue';
import { useToggle, useMediaQuery } from '@vueuse/core';

import {
  useAdvancedTable,
  useModal,
  useEchoServer,
  useTableDataSimplified
} from '@/lib/hooks';

import {
  FooterContainer,
  HeaderContainer,
  OrganizationSwitcher,
  ProductContainer,
  RoutesContainer,
  Overlay
} from '@/layouts/Sidebar/components';

import { Button } from '@/components/ui/Button';
import { ModalContainer } from '@/components/custom/Modals';

import ChevronRightIcon from 'assets/icons/chevron-right.svg?component';

import { isSidebarOpened, setIsSidebarOpen } from '@/lib/localStorage';

import {
  getCurrentListContentType,
  parseCurrentUserActiveProducts
} from '@/lib/helpers';

import { cn } from '@/lib/utils';
import { getInitialSorting } from '@/constants/sorting';

import {
  ALLOWED_BREAKPOINTS,
  BREAKPOINT_HORIZONTAL_MOBILE
} from '@/constants/layout';

import { COMPONENT_VARIANTS } from '@/constants/component';
import { INACTIVE_MODAL } from '@/constants/modals';
import { IDEA_DATABASE_ROUTE_ROLES } from '@/constants/user';

import type { AllowedRoutes } from '@/constants/routes';
import type { CurrentUser } from '@/types/users';

const currentRoute = ref<AllowedRoutes>(
  window.location.pathname as AllowedRoutes
);
const {
  handleCurrentListContentUpdate,
  handleCurrentFilteredIds,
  handleDeleteTableData,
  rowSelection,
  filteringParams
} = useAdvancedTable(getInitialSorting(currentRoute.value));
const { simplifiedData, isFetching } = useTableDataSimplified(
  getCurrentListContentType(currentRoute.value),
  filteringParams
);

const { currentModal, isModalLoading } = useModal({
  rowSelection,
  handleDeleteTableData
});
const isDesktop = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
const isSidebarOpen = ref(isSidebarOpened(isDesktop));
const toggleSidebar = useToggle(isSidebarOpen);
const isHorizontalMobileScreen = useMediaQuery(BREAKPOINT_HORIZONTAL_MOBILE);
const currentUser = ref<CurrentUser>(
  parseCurrentUserActiveProducts(window.currentUser)
);
provide('currentUser', currentUser);
useEchoServer(currentUser);

watch(isDesktop, (isDesktop, prevIsDesktop) => {
  if (
    (prevIsDesktop && !isDesktop && isSidebarOpen.value) ||
    (!prevIsDesktop && isDesktop && !isSidebarOpen.value)
  ) {
    toggleSidebar();
  }
});

watch(isSidebarOpen, value => {
  setIsSidebarOpen(value);
});

watch(isFetching, () => {
  handleCurrentFilteredIds(simplifiedData.value.map(({ id }) => id));
});

onBeforeMount(async () => {
  handleCurrentListContentUpdate(getCurrentListContentType(currentRoute.value));
});

/**
 * Grid layout:
 *                SM    MD    LG    XL    2XL   4XL
 * Total          8     8     12    16    16    24
 * Toggled off    0     0     1     1     1     1
 * Expanded       7*    7*    3     3     3     3
 * * This is drawer - displayed on top of the content
 */
</script>

<template>
  <Overlay v-if="!isDesktop && isSidebarOpen" @click="toggleSidebar()" />
  <Button
    v-if="!isSidebarOpen && !isDesktop"
    :variant="COMPONENT_VARIANTS.ghost"
    class="fixed z-10 w-12 top-5 left-5 h-11 p-2.5"
  >
    <ChevronRightIcon @click="toggleSidebar()" />
  </Button>
  <div
    class="font-medium font-poppins min-h-dvh bg-slate-50 dark:bg-slate-900 dark:text-white grid sm:grid-cols-8 md:grid-cols-8 lg:grid-cols-12 xl:grid-cols-16 4xl:grid-cols-24"
  >
    <aside
      :class="
        cn(
          'flex flex-col justify-between border-r bg-white border-gray-200 dark:bg-slate-900 dark:text-white',

          isDesktop && isSidebarOpen ? 'col-span-3' : 'col-span-1',
          !isDesktop && isSidebarOpen && 'fixed z-50 top-0 min-h-dvh w-[87.5%]',
          isHorizontalMobileScreen && 'h-full overflow-y-scroll',
          !isDesktop && !isSidebarOpen && 'hidden'
        )
      "
    >
      <div class="p-5 flex flex-col gap-5">
        <HeaderContainer
          :is-sidebar-open="isSidebarOpen"
          @toggle-sidebar="toggleSidebar"
        />

        <OrganizationSwitcher :is-sidebar-open="isSidebarOpen" />

        <RoutesContainer
          :current-route="currentRoute"
          :is-sidebar-open="isSidebarOpen"
        />

        <ProductContainer
          :is-sidebar-open="isSidebarOpen"
          :is-patents-platform-enabled="currentUser.isPatentsPlatformEnabled"
          :is-idea-database-enabled="
            currentUser.isIdeaDatabaseProductEnabled &&
            currentUser.ideaDatabaseRoles
              ?.split(', ')
              .some(role =>
                IDEA_DATABASE_ROUTE_ROLES().includes(
                  role as ReturnType<typeof IDEA_DATABASE_ROUTE_ROLES>[number]
                )
              )
          "
        />
      </div>

      <FooterContainer :is-sidebar-open="isSidebarOpen" />
    </aside>

    <main
      :class="
        cn(
          'sm:col-span-8 md:col-span-8 p-5 lg:p-8 pt-20 lg:pt-24',
          isSidebarOpen
            ? 'lg:col-span-9  xl:col-span-13 2xl:col-span-13 4xl:col-span-21'
            : 'lg:col-span-11 xl:col-span-15 2xl:col-span-15 4xl:col-span-23'
        )
      "
    >
      <slot></slot>
    </main>
  </div>
  <ModalContainer v-if="currentModal !== INACTIVE_MODAL || isModalLoading" />
</template>
