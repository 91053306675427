<script setup lang="ts">
import { computed, toRefs, inject, type Ref } from 'vue';

import { ConditionalTooltip } from '@/components/ui/Tooltip';
import { CustomSelect } from '@/components/custom/CustomSelect';

import AvatarPlaceholder from 'assets/icons/avatar-placeholder.svg?url';
import { cn } from '@/lib/utils';
import type { CurrentUser } from '@/types/users';

const props = defineProps<{
  isSidebarOpen: boolean;
}>();

const { isSidebarOpen } = toRefs(props);

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const selectOptions = computed(() =>
  currentUser.value.availableOrganizations.map(organization => ({
    label: organization.name,
    value: organization.id.toString() || '',
    selected: organization.id === currentUser.value.organization.id,
    disabled: organization.twofaForced && !currentUser.value.twoFactorEnabled,
    icon:
      organization.profilePictureUrl.length > 1
        ? organization.profilePictureUrl
        : AvatarPlaceholder
  }))
);

const profilePicturePath = computed(
  () => selectOptions.value.find(option => option.selected)?.icon
);

const selectedOrganization = computed(
  () => selectOptions.value.find(
    option => option.selected
  )!.value
);

function handleOptionSelect(organizationId: string) {
  window.location.href = `/auth/redirect?selected_organization=${organizationId}`;
}
</script>

<template>
  <ConditionalTooltip
    :should-show="!isSidebarOpen"
    :content="$t('organizationDashboard.switch_organization')"
    placement="right"
  >
    <CustomSelect
      :class="cn('disabled:opacity-1', !isSidebarOpen && 'p-1')"
      :content-class="
        cn(
          'break-word',
          isSidebarOpen ? 'max-w-[--radix-select-trigger-width]' : 'max-w-xs'
        )
      "
      :is-disabled="currentUser.availableOrganizations.length <= 1"
      :is-compact="!isSidebarOpen"
      :model-value="selectedOrganization"
      option-icon-class="bg-gray-100 border broder-gray-200"
      :options="selectOptions"
      has-small-text
      disabled-content="common.2faRequired"
      @update:model-value="handleOptionSelect($event)"
    >
      <template #trigger-icon>
        <div
          :class="
            cn(
              'w-7 h-7 flex justify-center items-center bg-gray-100 border border-gray-200 rounded shrink-0 overflow-hidden',
              isSidebarOpen && 'rounded w-4 h-4 mr-2'
            )
          "
        >
          <img
            :src="profilePicturePath"
            alt=""
            :class="
              cn(
                'object-contain w-auto max-w-7 h-auto max-h-7',
                isSidebarOpen && 'max-w-4 max-h-4'
              )
            "
          />
        </div>
      </template>
    </CustomSelect>
  </ConditionalTooltip>
</template>
