<script setup lang="ts">
import { toRefs } from 'vue';
import type { ParseKeys } from 'i18next';

import LinkIcon from 'assets/icons/link-03.svg?component';
import { ConditionalTooltip } from '@/components/ui/Tooltip';

import { cn } from '@/lib/utils';

const props = defineProps<{
  isSidebarOpen: boolean;
  href: string;
  translation: ParseKeys;
}>();
const { isSidebarOpen, href, translation } = toRefs(props);
</script>

<template>
  <ConditionalTooltip
    :should-show="!isSidebarOpen"
    :content="$t(translation)"
    placement="right"
    class="self-center h-[1.625rem]"
  >
    <a
      :href="href"
      target="_blank"
      :class="
        cn(
          'self-stretch items-center inline-flex',
          isSidebarOpen ? 'justify-between' : 'justify-center'
        )
      "
    >
      <span
        class="self-stretch justify-start items-center px-2 gap-2 inline-flex text-gray-800 text-sm font-medium dark:text-white"
      >
        <slot></slot>
        <span v-if="isSidebarOpen">{{ $t(translation) }}</span>
      </span>
      <LinkIcon v-if="isSidebarOpen" />
    </a>
  </ConditionalTooltip>
</template>
