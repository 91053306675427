import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplifiedByProduct } from '@/api/user';

import {
  USERS_SIMPLIFIED_ROLES_API,
  USERS_SIMPLIFIED_TYPE_API
} from '@/constants/api';

import { PRODUCT_PATENT_PLATFORM } from '@/constants/contentType';

import type {
  UserSimplifiedWithRole,
  UserSimplifiedWithType
} from '@/types/users';

import type { AllowedProducts } from '@/constants/contentType';

function useUsersSimplifiedByProduct(
  useParams = false,
  product: AllowedProducts
) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [
      product === PRODUCT_PATENT_PLATFORM
        ? USERS_SIMPLIFIED_TYPE_API
        : USERS_SIMPLIFIED_ROLES_API,
      selectedIds
    ],
    queryFn: () =>
      fetchUsersSimplifiedByProduct({
        user_ids: selectedIds.value,
        product
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedUsersWithAccess = computed<SimplifiedUserWithAccess[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedUsersWithAccess, isError, isFetching };
}

export default useUsersSimplifiedByProduct;

export type SimplifiedUserWithAccess =
  | UserSimplifiedWithType
  | UserSimplifiedWithRole;
