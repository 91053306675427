<script setup lang="ts">
import { inject, type Ref, toRefs } from 'vue';

import {
  Users,
  Home,
  CircleUserRound,
  KeyRound,
  Settings
} from 'lucide-vue-next';

import { RouteLink } from '@/layouts/Sidebar/components';

import { cn } from '@/lib/utils';

import {
  type AllowedRoutes,
  DASHBOARD_ROUTE,
  MANAGE_ACCOUNT_ROUTE,
  MANAGE_GROUPS_ROUTE,
  MANAGE_TOKEN_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE
} from '@/constants/routes';

import type { CurrentUser } from '@/types/users';

const props = defineProps<{
  currentRoute: AllowedRoutes;
  isSidebarOpen: boolean;
}>();

const { currentRoute, isSidebarOpen } = toRefs(props);
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <div
    :class="
      cn(
        'flex-col items-stretch gap-3 inline-flex',
        isSidebarOpen ? 'justify-start' : 'justify-center'
      )
    "
  >
    <RouteLink
      :icon="Home"
      :is-sidebar-open="isSidebarOpen"
      :href="DASHBOARD_ROUTE"
      :current-route="currentRoute"
      translation="organizationDashboard.dashboard"
    />
    <RouteLink
      v-if="
        (currentUser.isPatentsPlatformAvailable ||
          currentUser.isIdeaDatabaseAvailable) &&
        (currentUser.isIdeaDatabaseAdministrator ||
          currentUser.isPatentsPlatformAdministrator)
      "
      :icon="CircleUserRound"
      :is-sidebar-open="isSidebarOpen"
      :href="MANAGE_ACCOUNT_ROUTE"
      :current-route="currentRoute"
      translation="manageAccounts.manage_accounts"
    >
    </RouteLink>
    <RouteLink
      v-if="
        currentUser.isPatentsPlatformAvailable &&
        currentUser.isPatentsPlatformAdministrator
      "
      :icon="Users"
      :is-sidebar-open="isSidebarOpen"
      :href="MANAGE_GROUPS_ROUTE"
      :current-route="currentRoute"
      translation="manageGroups.manage_groups"
    >
    </RouteLink>
    <RouteLink
      v-if="
        currentUser.isPatentsPlatformAdministrator &&
        currentUser.isPatentsApiAvailable
      "
      :icon="KeyRound"
      :is-sidebar-open="isSidebarOpen"
      :href="MANAGE_TOKEN_ROUTE"
      :current-route="currentRoute"
      translation="manageTokens.manage_tokens"
    >
    </RouteLink>
    <RouteLink
      v-if="
        currentUser.isPatentsPlatformAdministrator ||
        currentUser.isIdeaDatabaseAdministrator
      "
      :icon="Settings"
      :is-sidebar-open="isSidebarOpen"
      :href="ORGANIZATION_SETTINGS_ROUTE"
      :current-route="currentRoute"
      translation="organizationDashboard.organization_settings"
    >
    </RouteLink>
  </div>
</template>
