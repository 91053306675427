<script setup lang="ts">
import { toRefs } from 'vue';

import { Button } from '@/components/ui/Button';
import { ConditionalTooltip } from '@/components/ui/Tooltip';

import IamIPLogo from 'assets/icons/iamip-logo-full-default.svg?component';
import ChevronLeftIcon from 'assets/icons/chevron-left.svg?component';
import ChevronRightIcon from 'assets/icons/chevron-right.svg?component';

import { cn } from '@/lib/utils';
import { COMPONENT_VARIANTS } from '@/constants/component';

const props = defineProps<{
  isSidebarOpen: boolean;
}>();
const { isSidebarOpen } = toRefs(props);
const emits = defineEmits<{
  (e: 'toggleSidebar'): void;
}>();
</script>

<template>
  <div
    :class="
      cn(
        'h-11 space-between items-center inline-flex',
        isSidebarOpen ? 'justify-between' : 'justify-center'
      )
    "
  >
    <div v-if="isSidebarOpen">
      <IamIPLogo />
    </div>
    <ConditionalTooltip
      :should-show="!isSidebarOpen"
      :content="$t('organizationDashboard.expand_sidebar')"
      placement="right"
    >
      <Button
        class="w-12 h-11 p-2.5"
        :variant="COMPONENT_VARIANTS.ghost"
        @click="emits('toggleSidebar')"
      >
        <ChevronRightIcon v-if="!isSidebarOpen" />
        <ChevronLeftIcon v-else />
      </Button>
    </ConditionalTooltip>
  </div>
</template>
