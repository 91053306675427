<script setup lang="ts">
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import { useTranslation } from 'i18next-vue';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select';

import { ScrollArea } from '@/components/ui/ScrollArea';
import { Label } from '@/components/ui/Label';

import { cn } from '@/lib/utils';
import { prepareMessage } from '@/lib/helpers';

import {
  STATUS_DEFAULT,
  STATUS_ERROR,
  STATUS_SUCCESS
} from '@/constants/status';

import type { CustomSelectProps } from '@/types/components';

const { t } = useTranslation();

const props = withDefaults(defineProps<CustomSelectProps>(), {
  id: '',
  class: null,
  modelValue: '',
  contentClass: '',
  defaultValue: '',
  hasSmallText: false,
  isCompact: false,
  isDisabled: false,
  messages: () => ({
    errorMessage: '',
    successMessage: ''
  }),
  placeholder: '',
  optionIconClass: '',
  options: () => [],
  validationStatus: STATUS_DEFAULT,
  triggerIcon: null
});

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true
});

const selectModel = ref(modelValue);

const inputMessage = computed(() =>
  prepareMessage(props.validationStatus, props.messages)
);

/*
 * INFO: Scroll inside the select portal will be triggered when content is overflowing in the SelectContent
 * Set max height to SelectContent in order to trigger the scroll
 * */
</script>

<template>
  <div :class="cn('h-min w-full', labelText && 'flex flex-col gap-2.5')">
    <Label v-if="labelText" :for="id" class="text-black"
      >{{ $t(labelText) }}
    </Label>
    <div class="gap-0 w-full">
      <Select :id="id" v-model="selectModel as string" :disabled="isDisabled">
        <SelectTrigger
          :class="
            cn(
              'w-full focus:outline-none focus:ring-2 focus:border-blue-500 focus:ring-blue-100 outline-blue-500 focus:ring-offset-0',
              isCompact && 'px-1',
              hasSmallText && 'text-sm',
              props.class
            )
          "
          :disabled="isDisabled"
          :is-compact="isCompact"
          :validation-status="validationStatus"
        >
          <div
            :class="
              cn(
                'flex items-center h-full w-full overflow-hidden shrink-0',
                isCompact && 'justify-center'
              )
            "
          >
            <slot name="trigger-icon"></slot>
            <SelectValue
              v-if="!isCompact"
              :placeholder="placeholder || t('manageAccounts.select_an_option')"
              class="truncate"
            />
          </div>
        </SelectTrigger>
        <SelectContent>
          <ScrollArea :class="contentClass">
            <SelectGroup>
              <SelectItem
                v-for="option in options"
                :key="option?.value"
                :disabled="option?.disabled"
                :value="option?.value as string"
                :class="hasSmallText ? 'text-sm' : null"
                :disabled-content="disabledContent"
              >
                <template #select-item-icon>
                  <div
                    v-if="option?.icon"
                    :class="
                      cn(
                        'w-4 h-4 flex justify-center items-center shrink-0 mr-2 rounded overflow-hidden',
                        props.optionIconClass
                      )
                    "
                  >
                    <img
                      :src="option?.icon"
                      class="w-4 h-4 object-contain"
                      alt=""
                    />
                  </div>
                </template>
                <template #select-item-text>
                  {{ option.label }}
                </template>
              </SelectItem>
            </SelectGroup>
          </ScrollArea>
        </SelectContent>
      </Select>
      <p
        v-if="validationStatus !== STATUS_DEFAULT"
        :class="
          cn(
            'text-sm mt-1',
            validationStatus === STATUS_ERROR && 'text-red-600',
            validationStatus === STATUS_SUCCESS && 'text-emerald-600'
          )
        "
      >
        {{ inputMessage }}
      </p>
    </div>
  </div>
</template>
