<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core';

import { Heading } from '@/components/custom';
import { cn } from '@/lib/utils';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';
import type { HTMLAttributes } from 'vue';

const props = defineProps<{
  heading: string;
  asideClass?: HTMLAttributes['class'];
  mainContentClass?: HTMLAttributes['class'];
}>();

const isLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
</script>

<template>
  <Heading size="h3" class="mb-7 min-h-9 h-max font-medium md:text-3xl">{{
    heading
  }}</Heading>

  <div
    class="grid grid-cols-1 lg:gap-3 lg:grid-cols-[1fr_minmax(220px,_20%)] lg:h-[calc(100dvh-12rem)]"
  >
    <main :class="cn('overflow-y-auto scroll-smooth', props.mainContentClass)">
      <slot name="main-content"></slot>
    </main>

    <aside
      v-if="isLargeScreen"
      :class="cn('border-l border-slate-300 pt-8 pl-5', props.asideClass)"
    >
      <slot name="aside-content"></slot>
    </aside>
  </div>
</template>
