<script setup lang="ts">
import {computed, inject, type Ref} from 'vue';
import { useTranslation } from 'i18next-vue';

import { CustomCard } from '@/components/custom';
import { Badge } from '@/components/ui/Badge';
import { UserInfoBlock } from '@/views/Accounts/components';

import { Users } from 'lucide-vue-next';

import {
  USER_PROFILE_DETAILS_LABELS,
  USER_PROFILE_PRODUCT_ACCESS_LABELS
} from '@/constants/user';

import { STATUS_GHOST } from '@/constants/status';

import type { ParseKeys } from 'i18next';
import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const { t } = useTranslation();

const userProfileDetails = [
  { name: 'createdAt', value: currentUser.value.createdAt },
  { name: 'lastLogin', value: currentUser.value.lastLogin },
  { name: 'loginType', value: currentUser.value.loginType }
];

const userProfileProductAccess = computed(() => [
  {
    name: 'patentsPlatformUserType',
    value: currentUser.value.patentsPlatformUserType
      ? t(`manageAccounts.${currentUser.value.patentsPlatformUserType}` as ParseKeys)
      : null
  },
  {
    name: 'ideaDatabaseRoles',
    value: currentUser.value.ideaDatabaseRoles
      ?.split(',')
      .map(role => t(`manageAccounts.${role.trim()}` as ParseKeys))
      .join(', ')
  }
]);
</script>

<template>
  <CustomCard :card-title="$t('userProfile.Profile')" has-header-separator>
    <template #card-content>
      <div class="flex flex-col xl:flex-row gap-8 w-full">
        <UserInfoBlock
          :full-name="currentUser.fullName"
          :email="currentUser.email"
          is-large
          is-option
          class="xl:max-w-[30%] shrink-0"
        />

        <div class="flex flex-col gap-8">
          <section
            class="flex flex-col 2xl:flex-row gap-6 2xl:gap-12 text-sm 2xl:max-w-[80%]"
          >
            <div class="flex flex-col gap-1.5 xl:gap-3 2xl:col-span-3">
              <div
                v-for="detail in userProfileDetails"
                :key="detail.name"
                class="grid grid-cols-2 sm:grid-cols-[9.375rem_minmax(9.375rem,1fr)] 2xl:grid-cols-[7.5rem_minmax(7.5rem,1fr)] gap-4 xl:gap-6"
              >
                <p>
                  {{
                    $t(
                      USER_PROFILE_DETAILS_LABELS[
                        detail.name as keyof typeof USER_PROFILE_DETAILS_LABELS
                      ] as ParseKeys
                    )
                  }}
                </p>
                <p class="font-normal">{{ detail.value }}</p>
              </div>
            </div>

            <div
              class="flex flex-col gap-1.5 2xl:gap-3 col-span-2 2xl:col-span-5"
            >
              <div
                v-for="productAccess in userProfileProductAccess"
                :key="productAccess.name"
                class="grid grid-cols-2 sm:grid-cols-[9.375rem_minmax(9.375rem,1fr)] 2xl:grid-cols-[12.5rem_minmax(9.375rem,1fr)] gap-4 xl:gap-6"
              >
                <p>
                  {{
                    $t(
                      USER_PROFILE_PRODUCT_ACCESS_LABELS[
                        productAccess.name as keyof typeof USER_PROFILE_PRODUCT_ACCESS_LABELS
                      ] as ParseKeys
                    )
                  }}
                </p>
                <p class="font-normal">
                  {{ productAccess.value || $t('common.unassigned') }}
                </p>
              </div>
            </div>
          </section>

          <div
            class="grid grid-cols-2 sm:grid-cols-[9.375rem_minmax(9.375rem,1fr)] lg:grid-cols-[7.5rem_minmax(7.5rem,1fr)] gap-4 xl:gap-6 2xl:max-w-[90%]"
          >
            <p class="text-sm">{{ $t('userProfile.member_of') }}</p>
            <div v-if="currentUser.groups?.length > 0" class="flex flex-wrap gap-1.5">
              <Badge
                v-for="group in currentUser.groups"
                :key="group"
                class="text-black min-h-7 max-w-56"
                :variant="STATUS_GHOST"
                shape="rectangular"
                size="medium"
              >
                <Users class="shrink-0 h-3 w-3" stroke-width="{1.5}" />
                <p class="truncate" :title="group">{{ group }}</p>
              </Badge>
            </div>
            <p v-else class="font-normal text-sm">{{ $t('common.none') }}</p>
          </div>
        </div>
      </div>
    </template>
  </CustomCard>
</template>
